import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect:'/home',
    component: () => import('@/components/Layout.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component:() => import('@/views/Home.vue')
      },
      {
        path: '/contact',
        name: 'contact',
        component:() => import('@/views/ContactUs.vue')
      },
    ]
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;
