import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { Button,Input,Dialog,Form,FormItem,Icon } from 'element-ui';

Vue.config.productionTip = false;
Vue.use(Button)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Icon)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
